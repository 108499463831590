import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from 'styled-system';

import Layout from '../components/Layout';
import Sitewidth from '../components/Sitewidth';
import TextBlock from '../components/TextBlock';
import Block from '../components/Block';
import ContactCard from '../components/Cards/ContactCard';
import CityCard from '../components/Cards/CityCard';
import AnimationHorizontal from '../components/Animate';

import photo1 from '../assets/images/belgrade.jpg';
import photo2 from '../assets/images/toronto.jpg';
import photo3 from '../assets/images/dubai.jpg';
import photo4 from '../assets/images/office.jpg';

const CITIES = [
  {
    label: 'United Arab Emirates',
    title: 'Dubai',
    src: photo3,
    to: 'mailto:careers@dxbee.io',
    utcOffset: 240,
  },
  {
    label: 'Serbia',
    title: 'Belgrade',
    src: photo1,
    to: 'https://thehive.rs/careers',
    utcOffset: 120,
  },
  {
    label: 'Canada',
    title: 'Toronto',
    src: photo2,
    to: 'mailto:careers@dxbee.io',
    utcOffset: -240,
  },
];

// const Overflow = styled.div`
//   width: 100%;
//   overflow: hidden;
// `;

const Content = styled(Block)``;

const ImageWrapper = styled(Block)`
  height: 200px;

  @media(min-width: ${themeGet('breakpoints.1')}) {
    height: 500px;
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    height: 700px;
  }
`;

const TextCardWrapper = styled.div`
  z-index: 1;
  width: 100%;
  margin-bottom: ${themeGet('space.4')};

  @media(min-width: ${themeGet('breakpoints.1')}) {
    position: absolute;
    right: 10%;
    top: -10%;
    left: auto;
    max-width: 500px;
    margin: 0 auto;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 10px;
`;

const CitiesWrapper = styled(Block)`
  background: ${themeGet('colors.secondary.base')};
`;

const Cities = styled(Block)`
  flex-direction: column;
  
  & > :not(:last-child) {
    margin-bottom: 20px;
  }

  @media(min-width: ${themeGet('breakpoints.1')}) {
    flex-direction: row;
    width: auto;
    
    & > :not(:last-child) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
`;

const Normalize = styled.div`
  width: 100%;
`;

const Contact = () => (
  <Layout>
    {/* <Overflow> */}
    <Sitewidth py={[4, 5]}>
      <TextBlock
        title="Contact us"
        description="Got big ideas? Something that you know could change
            the game, make a difference, or spark a revolution?
            Feel free to reach out to us."
        as="h1"
        size="default"
      />
    </Sitewidth>
    <Content my={[2, 3, 7]} p={[4, 4, 5]}>
      <TextCardWrapper>
        <AnimationHorizontal direction="btt" delay={200}>
          <ContactCard
            title="Design & Experience are recognized as critical factors in
                creating successful products and services."
            description="We merge business, technology and experience to build
                optimal products. We seeks out partnerships with clients and
                entrepreneurs rather than the traditional vendor relationship.
                We integrate ourself within their team and business.
                Our Team takes on the same emotional commitment to the product’s
                success as the clients, we’re all in it together."
          />
        </AnimationHorizontal>
      </TextCardWrapper>
      <AnimationHorizontal direction="btt" delay={100}>
        <ImageWrapper>
          <Image src={photo4} alt="" />
        </ImageWrapper>
      </AnimationHorizontal>
    </Content>
    <ThemeProvider theme={{ mode: 'dark' }}>
      <CitiesWrapper py={[5, 6, 7]}>
        <Sitewidth
          justifyContent={['center', null, null, 'flex-start']}
          alignItems={['center', null, null, 'flex-start']}
        >
          <AnimationHorizontal direction="btt">
            <TextBlock
              title="We are always looking for talents"
              quote="- select a location to start -"
              color="default"
            />
          </AnimationHorizontal>
          <Cities pt={[5, 6]}>
            {CITIES.map((item, index) => (
              <AnimationHorizontal
                key={item.title}
                direction="btt"
                delay={index * 100}
              >
                <Normalize>
                  <CityCard
                    label={item.label}
                    title={item.title}
                    src={item.src}
                    to={item.to}
                    utcOffset={item.utcOffset}
                    type={item.type}
                    as="h3"
                  />
                </Normalize>
              </AnimationHorizontal>
            ))}
          </Cities>
        </Sitewidth>
      </CitiesWrapper>
    </ThemeProvider>
    {/* </Overflow> */}
  </Layout>
);

export default Contact;
