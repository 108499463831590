/**
 * ContactCard
 * -----------
 * extends: Block
 */

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from 'styled-system';
import { timingFunctions } from 'polished';

import Block from '../../Block';
import { Label, Link, Paragraph } from '../../Text';

import ArrowRight from '../../../assets/icons/arrow-right.svg';

const StyledLabel = styled(Label)`
  font-size: ${themeGet('fontSizes.4')};
`;

const Row = styled(Block)`
  ${StyledLabel} {
    width: 100%;
    text-align: center;
    padding-bottom: ${themeGet('space.1')};
  }
`;

const Wrapper = styled(Block)`
  background: ${themeGet('colors.secondary.base')};
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 20px ${themeGet('colors.black.shade.3')};

  @media(min-width: ${themeGet('breakpoints.0')}) {
    max-width: 550px;
  }

  ${Row}:last-child > a:last-child:after {
    content: '';
    position: absolute;
    bottom: 2px;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: -5px;
    background: url(${ArrowRight}) no-repeat center;
    background-size: contain;
    opacity: 0;
    transition:
      transform 800ms ${timingFunctions('easeOutCirc')}, opacity 300ms;
  }
      
  ${Row}:last-child > a:last-child:hover:after {
    transform: translateX(10px);
    opacity: 1;
  }
`;

const StyledLink = styled(Link)`
  &:not(:last-child) { margin-bottom: 10px; }
  color: ${themeGet('colors.white.base')};
`;


const ContactCard = () => (
  <ThemeProvider theme={{ mode: 'dark' }}>
    <Wrapper p={[4, 5, 6]}>
      <Row pb={[4, 5]}>
        <StyledLabel>Business inquiries</StyledLabel>
        <StyledLink href="mailto:hello@dxbee.io" target="_blank">
          hello@dxbee.io
        </StyledLink>
        <StyledLink href="tel:‭+971559999583‬">‭+971 55 999 9583‬</StyledLink>
      </Row>
      <Row>
        <StyledLabel>Head office</StyledLabel>
        <Paragraph
          as="div"
          dangerouslySetInnerHTML={{
            __html: `Bay Square Building 6,
              Office #405,<br />Business Bay, Dubai, UAE`,
          }}
        />
        <StyledLink
          href="https://www.google.ae/maps/place/DXBEE+Media/@25.097809,
            55.1557475,19z/data=!3m1!4b1!4m5!3m4!1s0x3e5f6b417d3fff7f:
            0xbc0572be15d2415d!8m2!3d25.097809!4d55.156296?hl=en"
          target="_blank"
        >
          See on map
        </StyledLink>
      </Row>
    </Wrapper>
  </ThemeProvider>
);

export default ContactCard;
