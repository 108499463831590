/**
 * ImageCard
 * ---------
 * extends: Block
 * props:
 *  - title: [string] **required**
 *  - label: [string] **required**
 *  - src: [string] **required**
 *  - to: [string] **required**
 *  - utcOffset: [number] **required**
 *  - as: [string]
 */

import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from 'styled-system';
import { timingFunctions } from 'polished';
import moment from 'moment';
import PropTypes from 'prop-types';

import Block from '../../Block';
import { Title, Label } from '../../Text';

const Wrapper = styled(Block)`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  box-shadow: 1px 3px 20px ${themeGet('colors.black.shade.3')};
  overflow: hidden;
  cursor: pointer;

  @media(min-width: ${themeGet('breakpoints.1')}) {
    min-width: 206px;
    height: 206px;
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    width: 300px;
    height: 300px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  background: ${themeGet('colors.black.shade.1')};
  transition: background 1000ms;
  &:hover {
    background: transparent;
  }
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;

  transform: scale(1.1);
  transition: transform 3000ms ${timingFunctions('easeInOut')};
  &:hover {
    transform: scale(1);
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  &:hover ${Overlay} {
    transform: translateY(0);
  }

  &:hover ${Image} {
    transform: scale(1);
  }

  &:hover ${Title},
  &:hover ${Label} {
    transition: color 500ms ${timingFunctions('easeInOut')};
    color: ${themeGet('colors.white.base')};
    pointer-events: none;
  }
`;

const ImageCard = React.memo(({
  title, label, src, to, utcOffset, as,
}) => {
  const now = locale => moment().utcOffset(locale).format('HH:mm');
  const [time, setTime] = useState(now(utcOffset));
  let timeInterval = null;

  useEffect(() => {
    timeInterval = setInterval(() => setTime(now(utcOffset)), 1000);
    return () => clearInterval(timeInterval);
  });

  return (
    <ThemeProvider theme={{ mode: 'dark' }}>
      <Wrapper as="a" href={to} target="_blank">
        <Content>
          <Image src={src} />
          <Block
            p={[3, 4]}
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Overlay />
            <Title as={as}>{title}</Title>
            <Label>{label}</Label>
            <Title as={as}>{time}</Title>
          </Block>
        </Content>
      </Wrapper>
    </ThemeProvider>
  );
});

ImageCard.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  to: PropTypes.string,
  utcOffset: PropTypes.number.isRequired,
  as: PropTypes.string,
};

ImageCard.defaultProps = {
  to: '',
  as: 'h2',
};

export default ImageCard;
